import React from 'react';
import PropTypes from "prop-types";
import GetTranslation from "../../../helper/GetTranslation";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const RendererTable = (props) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            props.element.content[0].map((cell, cellIndex) => (
                                <TableCell key={cellIndex}>
                                    {
                                        GetTranslation({
                                            translations: props.element?.translations?.[0]?.[cellIndex],
                                            defaultContent: cell
                                        })
                                    }
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.element.content.map((row, rowIndex) => (
                        (rowIndex > 0) &&
                        <TableRow key={rowIndex}>
                            {
                                row.map((cell, cellIndex) => (
                                    <TableCell scope="row" key={cellIndex}>
                                        {
                                            GetTranslation({
                                                translations: props.element?.translations?.[rowIndex]?.[cellIndex],
                                                defaultContent: cell
                                            })
                                        }
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

RendererTable.propTypes = {
    element: PropTypes.object.isRequired
}

export default RendererTable;